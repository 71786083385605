import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IManufacturerCreate, IManufacturerRead, IManufacturerUpdate} from '../models/manufacturer';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }


  static readonly GetManufacturersListPath = '/v1/manufacturer';
  static readonly CreateManufacturerPath = '/v1/manufacturer';
  static readonly GetPlaceByIdPath = '/v1/manufacturer/{manufacturer_id}';
  static readonly UpdateManufacturerPath = '/v1/manufacturer/{manufacturer_id}';
  static readonly RemoveManufacturerPath = '/v1/manufacturer/{manufacturer_id}';

  getList(params: {
    group_id: string;
    page?: number;
    size?: number;
  },
  context?: HttpContext

): Observable<IGetResponsePaginated<IManufacturerRead>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerService.GetManufacturersListPath, 'get');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IManufacturerRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IManufacturerRead>>) => r.body as IGetResponsePaginated<IManufacturerRead>)
    );
  }


  create(params: {
    group_id: string;
    body: IManufacturerCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IManufacturerRead>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerService.CreateManufacturerPath, 'post');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IManufacturerRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IManufacturerRead>>) => r.body as IPostResponse<IManufacturerRead>)
    );
  }

  getById(params: {
    manufacturer_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IManufacturerRead>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerService.GetPlaceByIdPath, 'get');
    if (params) {
      rb.path('manufacturer_id', params.manufacturer_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IManufacturerRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IManufacturerRead>>) => r.body as IGetResponse<IManufacturerRead>)
    );
  }

  update(params: {
    manufacturer_id: string;
    group_id: string;
    body: IManufacturerUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<IManufacturerRead>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerService.UpdateManufacturerPath, 'put');
    if (params) {
      rb.path('manufacturer_id', params.manufacturer_id, {});
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IManufacturerRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IManufacturerRead>>) => r.body as IPostResponse<IManufacturerRead>)
    );
  }


  remove(params: {
    manufacturer_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IManufacturerRead>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerService.RemoveManufacturerPath, 'delete');
    if (params) {
      rb.path('manufacturer_id', params.manufacturer_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IManufacturerRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IManufacturerRead>>) => r.body as IDeleteResponse<IManufacturerRead>)
    );
  }
}
