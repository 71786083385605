export const sensorsLink = {
  'd1f17447814d': 'Sensor 1',
  'c4c3590132c5': 'Sensor 2',
  'e46d874c67dc': 'Sensor 3',
  'd7a055357fb8': 'Sensor 4',
  'e077c8a636bd': 'Sensor 5',
  'e8597a2a1885': 'Sensor 6',
  'c847481ed9bc': 'Sensor 7',
  'cd7bf6119a7a': 'Sensor 8',
  'ef54b24f0412': 'Sensor 9'
}
