import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule } from 'ngx-quicklink';
import {DashboardComponent} from './dashboard.component';
import {DashboardV2Component} from './dashboard-v2.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardV2Component
  },
  {
    path: 'health',
    component: DashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, QuicklinkModule]
})
export class DashboardRoutingModule {
}
