<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-xs">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="w-64" src="assets/img/icons/logos/lll_logo.png">
      </div>
    </div>

    <div class="text-center mt-4">
      <h2 class="title m-0">Welcome to Spotlight</h2>
      <h4 class="body-2 text-secondary m-0">Sign in with your credentials below.</h4>
    </div>

    <div [formGroup]="form" class="p-6 flex flex-col gap-4">
      <div class="flex flex-col">
        <mat-form-field class="flex-1">
          <mat-label>e-mail</mat-label>
          <input formControlName="email" matInput required>
          <mat-error *ngIf="form.get('email').hasError('required')">We need an email address to log you in</mat-error>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>password</mat-label>
          <input [type]="inputType" formControlName="password" matInput required>
          <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
            <mat-icon *ngIf="visible" svgIcon="mat:visibility"></mat-icon>
            <mat-icon *ngIf="!visible" svgIcon="mat:visibility_off"></mat-icon>
          </button>
          <mat-hint>Click the eye to toggle visibility</mat-hint>
          <mat-error *ngIf="form.get('password').hasError('required')">We need a password to log you in</mat-error>
        </mat-form-field>
      </div>

      <div class="flex items-center justify-between">
        <mat-checkbox class="caption" color="primary">Remember Me</mat-checkbox>
        <a [routerLink]="['/forgot-password']" class="caption">Forgot Password?</a>
      </div>

      <button (click)="send()" color="primary" mat-raised-button type="button">
        SIGN IN
      </button>

    </div>
  </div>
</div>
