import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelectChange } from '@angular/material/select';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {fadeInUp400ms} from '../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../@vex/animations/stagger.animation';
import {TagCreateUpdateComponent} from './tag-create-update/tag-create-update.component';
import {tagsTableData} from '../../static-data/tags-data';
import {ITagCreate, ITagRead, ITagReadWithAsset} from '../common/models/tag';
import {TagService} from '../common/services/tag.service';
import {IGetResponsePaginated} from '../common/models/get-response';
import {IReaderCreate, IReaderRead} from '../common/models/reader';
import {ReaderCreateUpdateComponent} from '../readers/reader-create-update/reader-create-update.component';
import {BaseListComponent} from '../common/base/base-list.component';
import {IAssetRead} from '../common/models/assets';
import {ContextService} from '../common/services/context.service';


@UntilDestroy()
@Component({
  selector: 'vex-aio-table',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class TagsListComponent extends BaseListComponent<ITagRead> implements OnInit {

  layoutCtrl = new UntypedFormControl('fullwidth');

  @Input()
  columns: TableColumn<ITagReadWithAsset>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Label', property: 'label', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'MAC Address', property: 'mac_address', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Model', property: 'model', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Assigned To', property: 'linked_asset', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Battery', property: 'battery', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];
  private tagsResponse: IGetResponsePaginated<ITagReadWithAsset>;

  constructor(private dialog: MatDialog, private tagService: TagService, protected contextService: ContextService) {
    super(contextService);
  }

  async load() {
    this.$loading = true;
    this.tagsResponse = await this.tagService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.dataSource.data = this.tagsResponse.data.items;
    this.paginator.pageIndex = (this.tagsResponse.data.page - 1);
    this.paginator.length = this.tagsResponse.data.total;
    this.$loading = false;
  }

  async create() {
    const tag: ITagCreate = await this.dialog.open(TagCreateUpdateComponent).afterClosed().toPromise();
    if (tag) {
      const createdAsset = await this.tagService.create({body: tag}).toPromise();
      this.page = 0;
      await this.load();
    }
  }

  async update(tag: ITagRead) {
    const updatedAsset = await this.dialog.open(TagCreateUpdateComponent, {
      data: tag
    }).afterClosed().toPromise();
    if (updatedAsset) {
      const res = await this.tagService.update(
        {tag_id: tag.id, body: updatedAsset}).toPromise()
      this.page = 0;
      await this.load();
    }
  }

  async delete(tag: ITagRead) {
    await this.tagService.remove({tag_id: tag.id}).toPromise();
    this.page = 0;
    await this.load();
  }
}
