import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {TokenService} from './token.service';
import {AggregationZoneChangeEvent} from '../models/aggregation';

@Injectable({
  providedIn: 'root',
})
export class AggregationService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetAggregationsPath = '/v1/aggregations/time_on_zones_by_tag';


  getList(params?: {
            page?: number;
            size?: number;
          },
          context?: HttpContext
  ): Observable<IGetResponsePaginated<AggregationZoneChangeEvent>> {

    const rb = new RequestBuilder(this.rootUrl, AggregationService.GetAggregationsPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('tag_mac_address', 'E8:59:7A:2A:18:85', {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<AggregationZoneChangeEvent>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<AggregationZoneChangeEvent>>) => r.body as IGetResponsePaginated<AggregationZoneChangeEvent>)
    );
  }
}
