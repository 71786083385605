import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataStreamsListRoutingModule } from './data-streams-list-routing.module';
import { DataStreamsListComponent } from './data-streams-list.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {PageLayoutModule} from '../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../@vex/components/breadcrumbs/breadcrumbs.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {DataStreamsCreateUpdateModule} from './data-streams-create-update/data-streams-create-update.module';


@NgModule({
  declarations: [DataStreamsListComponent],
  imports: [
    CommonModule,
    DataStreamsListRoutingModule,
    PageLayoutModule,
    BreadcrumbsModule,
    DataStreamsCreateUpdateModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressBarModule,

    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonToggleModule
  ]
})
export class DataStreamsListModule {
}
