import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPlaceCreate, IPlaceRead, IPlaceReadWithMetadata, IPlaceUpdate} from '../models/place';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';


@Injectable({
  providedIn: 'root',
})
export class PlaceService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetPlacesListPath = '/v1/place';
  static readonly CreatePlacePath = '/v1/place';
  static readonly GetPlaceByIdPath = '/v1/place/{place_id}';
  static readonly UpdatePlacePath = '/v1/place/{place_id}';
  static readonly RemovePlacePath = '/v1/place/{place_id}';

  getList(params: {
            page?: number;
            size?: number;
          },
          context?: HttpContext

  ): Observable<IGetResponsePaginated<IPlaceRead>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.GetPlacesListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IPlaceRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IPlaceRead>>) => r.body as IGetResponsePaginated<IPlaceRead>)
    );
  }


  create(params: {
           body: IPlaceCreate
         },
         context?: HttpContext

  ): Observable<IPostResponse<IPlaceRead>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.CreatePlacePath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IPlaceRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IPlaceRead>>) => r.body as IPostResponse<IPlaceRead>)
    );
  }

  getById(params: {
            place_id: string;
          },
          context?: HttpContext

  ): Observable<IGetResponse<IPlaceReadWithMetadata>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.GetPlaceByIdPath, 'get');
    if (params) {
      rb.path('place_id', params.place_id, {});
      rb.query('group_id',this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IPlaceReadWithMetadata>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IPlaceReadWithMetadata>>) => r.body as IGetResponse<IPlaceReadWithMetadata>)
    );
  }




  updatePlaceApiV1PlacePlaceIdPut$Response(params: {
                                             place_id: string;
                                             body: IPlaceUpdate
                                           },
                                           context?: HttpContext

  ): Observable<IPostResponse<IPlaceRead>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.UpdatePlacePath, 'put');
    if (params) {
      rb.path('place_id', params.place_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IPlaceRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IPlaceRead>>) => r.body as IPostResponse<IPlaceRead>)
    );
  }


  remove(params: {
           place_id: string;
         },
         context?: HttpContext

  ): Observable<IDeleteResponse<IPlaceRead>> {

    const rb = new RequestBuilder(this.rootUrl, PlaceService.RemovePlacePath, 'delete');
    if (params) {
      rb.path('place_id', params.place_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IPlaceRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IPlaceRead>>) => r.body as IDeleteResponse<IPlaceRead>)
    );
  }
}
