import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {TokenService} from './token.service';

@Injectable()
export class BaseService {
  constructor( protected http: HttpClient, protected tokenService: TokenService) {
  }

  private _rootUrl: string = '';
  get rootUrl(): string {
    return this._rootUrl || environment.api;
  }

  set rootUrl(rootUrl: string) {
    this._rootUrl = rootUrl;
  }

  get groupId(): string {
    return this.tokenService.getGroup()?.id;
  }
}
