import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IUserCreate, IUserRead} from '../models/user';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IDeleteResponse} from '../models/delete-response';
import {IPostResponse} from '../models/post-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly ReadUsersListPath = '/v1/user/list';
  static readonly GetUserByIdPath = '/v1/user/{user_id}';
  static readonly RemoveUserPath = '/v1/user/{user_id}';
  static readonly GetMyDataPath = '/v1/user';
  static readonly CreateUserPath = '/v1/user';

  readUsersListApiV1UserListGet$Response(params: {
    group_id: string;
    page?: number;
    size?: number;
  },
  context?: HttpContext

): Observable<IGetResponsePaginated<IUserRead>>{

    const rb = new RequestBuilder(this.rootUrl, UserService.ReadUsersListPath, 'get');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IUserRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IUserRead>>) => r.body as IGetResponsePaginated<IUserRead>)
    );
  }


  getById(params: {
    user_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IUserRead>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.GetUserByIdPath, 'get');
    if (params) {
      rb.path('user_id', params.user_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IUserRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IUserRead>>) => r.body as IGetResponse<IUserRead>)
    );
  }

  remove(params: {
    user_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IUserRead>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.RemoveUserPath, 'delete');
    if (params) {
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IUserRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IUserRead>>) => r.body as IDeleteResponse<IUserRead>)
    );
  }


  getMyData(params?: {},context?: HttpContext): Observable<IGetResponse<IUserRead>> {
    const rb = new RequestBuilder(this.rootUrl, UserService.GetMyDataPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IUserRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IUserRead>>) => r.body as IGetResponse<IUserRead>)
    );
  }


  create(params: {
    group_id: string;
    body: IUserCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IUserRead>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.CreateUserPath, 'post');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IUserRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IUserRead>>) => r.body as IPostResponse<IUserRead>)
    );
  }
}
