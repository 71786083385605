<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>

    <h2 *ngIf="form.get('label').value"
        class="headline m-0 flex-auto">{{ form.get('label').value }}</h2>
    <h2 *ngIf="!form.get('label').value"
        class="headline m-0 flex-auto">New Tag</h2>

    <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Label</mat-label>
        <input cdkFocusInitial formControlName="label" matInput>

        <mat-icon class="mr-3" matPrefix svgIcon="mat:title"></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Serial Number</mat-label>
        <input formControlName="serial_number" matInput>

        <mat-icon class="mr-3" matPrefix svgIcon="mat:data_object"></mat-icon>
      </mat-form-field>
    </div>

    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Model</mat-label>
        <input formControlName="model" matInput>

        <mat-icon class="mr-3" matPrefix svgIcon="mat:tag"></mat-icon>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>MAC Address</mat-label>
        <input formControlName="mac_address" matInput>

        <mat-icon class="mr-3" matPrefix svgIcon="mat:label"></mat-icon>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit">Create Tag</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit">Update Tag</button>
  </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
