import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse} from '../models/get-response';
import {IFloorplanCreate, IFloorplanRead, IFloorplanUpdate} from '../models/floorplan';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';


@Injectable({
  providedIn: 'root',
})
export class FloorplanService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }


  static readonly GetFloorplanByIdPath = '/v1/floorplan/{floorplan_id}';
  static readonly UpdatePlacePath = '/v1/floorplan/{floorplan_id}';
  static readonly RemoveFloorplanPath = '/v1/floorplan/{floorplan_id}';
  static readonly CreateFloorplanPath = '/v1/floorplan';
  getById(params: {
    floorplan_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IFloorplanRead>> {

    const rb = new RequestBuilder(this.rootUrl, FloorplanService.GetFloorplanByIdPath, 'get');
    if (params) {
      rb.path('floorplan_id', params.floorplan_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IFloorplanRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IFloorplanRead>>) => r.body as IGetResponse<IFloorplanRead>)
    );
  }


  update(params: {
    floorplan_id: string;
    group_id: string;
    body: IFloorplanUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<IFloorplanRead>> {

    const rb = new RequestBuilder(this.rootUrl, FloorplanService.UpdatePlacePath, 'put');
    if (params) {
      rb.path('floorplan_id', params.floorplan_id, {});
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IFloorplanRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IFloorplanRead>>) => r.body as IPostResponse<IFloorplanRead>)
    );
  }


  remove(params: {
    floorplan_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IFloorplanRead>> {

    const rb = new RequestBuilder(this.rootUrl, FloorplanService.RemoveFloorplanPath, 'delete');
    if (params) {
      rb.path('floorplan_id', params.floorplan_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IFloorplanRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IFloorplanRead>>) => r.body as IDeleteResponse<IFloorplanRead>)
    );
  }

  create(params: {
    group_id: string;
    body: IFloorplanCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IFloorplanRead>> {

    const rb = new RequestBuilder(this.rootUrl, FloorplanService.CreateFloorplanPath, 'post');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IFloorplanRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IFloorplanRead>>) => r.body as IPostResponse<IFloorplanRead>)
    );
  }

}
