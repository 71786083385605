import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IDataStreamCreate, IDataStreamUpdate} from '../../common/models/data-stream';

@Component({
  selector: 'vex-customer-create-update',
  templateUrl: './data-streams-create-update.component.html',
  styleUrls: ['./data-streams-create-update.component.scss']
})
export class DataStreamsCreateUpdateComponent implements OnInit {
  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<DataStreamsCreateUpdateComponent>,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as IDataStreamCreate;
    }

    this.form = this.fb.group({
      name: this.defaults.name,
      protocol: [this.defaults.protocol || ''],
      host: [this.defaults.host || ''],
      port: [this.defaults.port || ''],
      path: [this.defaults.path || ''],
      username: [this.defaults.username || ''],
      password: [this.defaults.password || ''],
    });
  }

  save() {
    if (this.mode === 'create') {
      this.create();
    } else if (this.mode === 'update') {
      this.update();
    }
  }

  create() {
    const data_stream = this.form.value;
    this.dialogRef.close(data_stream);
  }

  update() {
    const data_stream = this.form.value as IDataStreamUpdate;
    data_stream.id = this.defaults.id;
    this.dialogRef.close(data_stream);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
