<vex-page-layout>

  <vex-page-layout-header class="pb-16 flex flex-col items-start justify-center">
    <div [class.container]="layoutCtrl.value === 'boxed'"
         [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
         class="w-full flex flex-col sm:flex-row justify-between">
      <div>
        <h1 class="title mt-0 mb-1">Movement report</h1>
        <vex-breadcrumbs [crumbs]="['Reports', 'Movement']"></vex-breadcrumbs>
      </div>

      <div class="hidden sm:block">
        <mat-button-toggle-group [formControl]="layoutCtrl" class="mt-4 sm:mt-0">
          <mat-button-toggle value="boxed">Boxed</mat-button-toggle>
          <mat-button-toggle value="fullwidth">Full-Width</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </vex-page-layout-header>

  <vex-page-layout-content [class.container]="layoutCtrl.value === 'boxed'"
                           [class.px-gutter]="layoutCtrl.value === 'fullwidth'"
                           class="-mt-6">

    <div class="card overflow-auto -mt-16">
      <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
        <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
          <span>Movement</span>
        </h2>

        <div class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
          <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
          <input [formControl]="searchCtrl"
                 class="px-4 py-2 border-0 outline-none w-full bg-transparent"
                 placeholder="Search an asset..."
                 type="search">
        </div>
        <div class="px-4 max-w-[300px] flex-auto flex items-center">
          <mat-form-field class="flex-auto">
            <mat-label>Filter from date</mat-label>
            <input (click)="formDatepickerRef.open()"
                   (focus)="formDatepickerRef.open()"
                   [matDatepicker]="formDatepickerRef"
                   matInput
                   readonly>
            <mat-datepicker-toggle [for]="formDatepickerRef" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #formDatepickerRef></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="px-4 max-w-[300px] flex-auto flex items-center">
          <mat-form-field class="flex-auto">
            <mat-label>Filter to date</mat-label>
            <input (click)="toDatepickerRef.open()"
                   (focus)="toDatepickerRef.open()"
                   [matDatepicker]="toDatepickerRef"
                   matInput
                   readonly>
            <mat-datepicker-toggle [for]="toDatepickerRef" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #toDatepickerRef></mat-datepicker>
          </mat-form-field>
        </div>

        <span class="flex-1"></span>
<!--
        <button [matMenuTriggerFor]="columnFilterMenu"
                class="ml-4 flex-none"
                mat-icon-button
                matTooltip="Filter Columns"
                type="button">
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>-->
      </div>

      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
            <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header> {{ column.label }}</th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
          </ng-container>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleColumns;"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row></tr>
      </table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" class="sticky left-0"></mat-paginator>
    </div>

  </vex-page-layout-content>

</vex-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
          class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>
