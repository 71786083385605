import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IReaderCreate, IReaderRead, IReaderUpdate} from '../models/reader';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';


@Injectable({
  providedIn: 'root',
})
export class ReaderService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetReadersListPath = '/v1/reader';
  static readonly CreateReaderPath = '/v1/reader';
  static readonly GetReaderByIdPath = '/v1/reader/{reader_id}';
  static readonly UpdateReaderPath = '/v1/reader/{reader_id}';
  static readonly RemoveReaderPath = '/v1/reader/{reader_id}';


  getList(params: {
    page?: number;
    size?: number;
  },
  context?: HttpContext

): Observable<IGetResponsePaginated<IReaderRead>> {

    const rb = new RequestBuilder(this.rootUrl, ReaderService.GetReadersListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IReaderRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IReaderRead>>) => r.body as IGetResponsePaginated<IReaderRead>)
    );
  }

  create(params: {
    body: IReaderCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IReaderRead>> {

    const rb = new RequestBuilder(this.rootUrl, ReaderService.CreateReaderPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IReaderRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IReaderRead>>) => r.body as IPostResponse<IReaderRead>)
    );
  }

  getById(params: {
    reader_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IReaderRead>> {

    const rb = new RequestBuilder(this.rootUrl, ReaderService.GetReaderByIdPath, 'get');
    if (params) {
      rb.path('reader_id', params.reader_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IReaderRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IReaderRead>>) => r.body as IGetResponse<IReaderRead>)
    );
  }


  update(params: {
    reader_id: string;
    body: IReaderUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<IReaderRead>> {

    const rb = new RequestBuilder(this.rootUrl, ReaderService.UpdateReaderPath, 'put');
    if (params) {
      rb.path('reader_id', params.reader_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IReaderRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IReaderRead>>) => r.body as IPostResponse<IReaderRead>)
    );
  }

  remove(params: {
    reader_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IReaderRead>> {

    const rb = new RequestBuilder(this.rootUrl, ReaderService.RemoveReaderPath, 'delete');
    if (params) {
      rb.path('reader_id', params.reader_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IReaderRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IReaderRead>>) => r.body as IDeleteResponse<IReaderRead>)
    );
  }
}
