import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {fadeInUp400ms} from '../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../@vex/animations/stagger.animation';
import {ReaderService} from '../common/services/reader.service';
import {IReaderUpdate, IReaderRead, IReaderCreate} from '../common/models/reader';
import {IGetResponsePaginated} from '../common/models/get-response';
import {ReaderCreateUpdateComponent} from './reader-create-update/reader-create-update.component';
import {BaseListComponent} from '../common/base/base-list.component';
import {ContextService} from '../common/services/context.service';


@UntilDestroy()
@Component({
  selector: 'lll-readers-list',
  templateUrl: './readers-list.component.html',
  styleUrls: ['./readers-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class ReadersListComponent extends BaseListComponent<IReaderRead> implements OnInit {
  @Input()
  columns: TableColumn<IReaderRead>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Label', property: 'label', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'MAC Address', property: 'mac_address', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Serial Number', property: 'serial_number', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Location', property: 'lastLocation', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Manufacturer', property: 'manufacturer_id', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  private assetsResponse: IGetResponsePaginated<IReaderRead>;

  constructor(private dialog: MatDialog, private readerService: ReaderService, protected contextService: ContextService) {
    super(contextService);
  }

  async load() {
    this.$loading = true;
    this.assetsResponse = await this.readerService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.dataSource.data = this.assetsResponse.data.items;
    this.paginator.pageIndex = (this.assetsResponse.data.page - 1);
    this.paginator.length = this.assetsResponse.data.total;
    this.$loading = false;
  }

  async create() {
    const reader: IReaderCreate = await this.dialog.open(ReaderCreateUpdateComponent).afterClosed().toPromise();
    if (reader) {
      const createdAsset = await this.readerService.create({body: reader}).toPromise();
      this.page = 0;
      await this.load();
    }
  }

  async update(reader: IReaderUpdate) {
    const updatedAsset = await this.dialog.open(ReaderCreateUpdateComponent, {
      data: reader
    }).afterClosed().toPromise();
    if (updatedAsset) {
      const res = await this.readerService.update(
        {reader_id: reader.id, body: updatedAsset}).toPromise()
      this.page = 0;
      await this.load();
    }
  }

  async delete(reader: IReaderRead) {
    await this.readerService.remove({reader_id: reader.id}).toPromise();
    this.page = 0;
    await this.load();
  }
}
