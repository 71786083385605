import { Component, Inject, OnInit } from '@angular/core';
import {FormControl, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IAssetCreate, IAssetUpdate} from '../../common/models/assets';
import {debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {TagService} from '../../common/services/tag.service';

@Component({
  selector: 'vex-asset-create-update',
  templateUrl: './asset-create-update.component.html',
  styleUrls: ['./asset-create-update.component.scss']
})
export class AssetCreateUpdateComponent implements OnInit {
  form: UntypedFormGroup;
  mode: 'create' | 'update' = 'create';
  linkedTagCtrl = new FormControl();
  filteredTags: any;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selectedTag: any = "";

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<AssetCreateUpdateComponent>,
              private fb: UntypedFormBuilder,
              private tagService: TagService,) {
  }

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
    } else {
      this.defaults = {} as IAssetCreate;
    }

    this.form = this.fb.group({
      label: this.defaults.label,
      serial_number: [this.defaults.serial_number || ''],
    });
    this.form.addControl("linked_tag_id", this.linkedTagCtrl);

    this.linkedTagCtrl.valueChanges
      .pipe(
        filter(res => {
          return res !== null && res.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filteredTags = [];
          this.isLoading = true;
        }),
        switchMap((value: string) => this.tagService.getFree(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe((data: any) => {
        this.filteredTags = data;
        console.log(this.filteredTags);
      });
  }

  onSelected($tag: any) {
    console.log($tag);
    this.selectedTag = $tag?.option?.value;
    this.linkedTagCtrl.setValue(this.selectedTag?.label || "");
  }

  clearSelection() {
    this.selectedTag = "";
    this.filteredTags = [];
  }

  save() {
    if (this.mode === 'create') {
      this.create();
    } else if (this.mode === 'update') {
      this.update();
    }
  }

  create() {
    const asset = this.form.value;

    this.dialogRef.close(asset);
  }

  update() {
    const asset = this.form.value as IAssetUpdate;
    asset.id = this.defaults.id;
    if (this.selectedTag) {
      if(!asset.linked_tags) {
        asset.linked_tags = [];
      }
      if(!asset.linked_tags.find(t => t.id === this.selectedTag.id)) {
        asset.linked_tags.push(this.selectedTag);
      }
    }
    console.log(asset);

    this.dialogRef.close(asset);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
