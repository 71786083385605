import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IGroupRead} from '../models/group';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private group$ = new BehaviorSubject<IGroupRead>(null);
  selectedGroup$ = this.group$.asObservable();
  constructor(private tokenService: TokenService) {}

  setGroup(group: IGroupRead) {
    this.tokenService.saveGroup(group);
    this.group$.next(group);
  }
}
