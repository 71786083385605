import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {AssetsListModule} from './assets/assets-list.module';
import {TagsListModule} from './tags/tags-list.module';
import {ReportsModule} from './reports/reports.module';
import {MapResolver} from './common/resolvers/map.resolver';
import {LoginModule} from './auth/login.module';
import {TokenService, AuthService, TagService, UserService, ZoneService, AssetService, FloorplanService, GroupService,
 ManufacturerService, PlaceService, RoleService, ReaderService} from './common/services';
import {AuthInterceptor} from './common/interceptors/auth.interceptor';
import {Router} from '@angular/router';
import {ReadersListModule} from './readers/readers-list.module';
import {GroupsPickModule} from './groups/groups-pick.module';
import { GoogleMapsModule } from '@angular/google-maps';
import {MqttBrokerService} from './common/services/mqttService';
import {IMqttServiceOptions, MqttModule} from 'ngx-mqtt';
import {DataStreamsListModule} from './data-streams/data-streams-list.module';
import {DataStreamService} from './common/services/data-stream.service';
import {AggregationService} from './common/services/aggregation.service';
import {loadGoogleMaps} from "./common/providers/google_maps_provider";
import {environment} from "../environments/environment";
import {EventService} from './common/services/event.service';
export const connection: IMqttServiceOptions = {
  hostname: '192.168.0.9',
  port: 8083,
  path: '/',
  clean: true,
  connectTimeout: 4000,
  reconnectPeriod: 4000,
  clientId: 'mqttx_597046f4',
  username: '',
  password: '',
  protocol: 'ws',
  connectOnCreate: false,
} as IMqttServiceOptions;
@NgModule({
  declarations: [AppComponent, ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Vex
    VexModule,
    CustomLayoutModule,
    MqttModule.forRoot(connection),
    LoginModule,
    GroupsPickModule,
    DashboardModule,
    AssetsListModule,
    ReadersListModule,
    TagsListModule,
    DataStreamsListModule,
    ReportsModule,
    GoogleMapsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, deps: [TokenService, AuthService, Router], multi: true },
    TokenService, AuthService, TagService, UserService, ZoneService, AssetService, FloorplanService, GroupService,
    ManufacturerService, PlaceService, RoleService, ReaderService, DataStreamService, MqttBrokerService,
    AggregationService, EventService, MapResolver],
  bootstrap: [AppComponent]
})
export class AppModule { }
