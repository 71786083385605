import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsListRoutingModule } from './tags-list-routing.module';
import { TagsListComponent } from './tags-list.component';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {PageLayoutModule} from '../../@vex/components/page-layout/page-layout.module';
import {BreadcrumbsModule} from '../../@vex/components/breadcrumbs/breadcrumbs.module';
import {TagCreateUpdateModule} from './tag-create-update/tag-create-update.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
  declarations: [TagsListComponent],
    imports: [
        CommonModule,
        TagsListRoutingModule,
        PageLayoutModule,
        BreadcrumbsModule,
        TagCreateUpdateModule,
        MatPaginatorModule,
        MatTableModule,
        MatSortModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,

        FormsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatProgressBarModule
    ]
})
export class TagsListModule {
}
