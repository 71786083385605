import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fadeInUp400ms } from '../../@vex/animations/fade-in-up.animation';
import { AuthService } from '../common/services/auth.service';
import {TokenService} from '../common/services/token.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {UserService} from '../common/services/user.service';

@Component({
  selector: 'vex-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    fadeInUp400ms
  ]
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;

  inputType = 'password';
  visible = false;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private cd: ChangeDetectorRef,
              private snackbar: MatSnackBar,
              private authService: AuthService,
              private tokenService: TokenService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async send() {
    const login_data = await this.authService.login(this.form.controls['email'].value,
      this.form.controls['password'].value).pipe(
        catchError((error) => {
          this.snackbar.open(error?.error?.detail, 'Close', {
            duration: 2000
          });
          return of(null);
        })
    ).toPromise();
    if (!login_data) {
      return;
    }
    this.tokenService.saveToken(login_data?.data?.access_token);
    this.tokenService.saveRefreshToken(login_data?.data?.refresh_token);
    this.tokenService.saveUser(login_data?.data?.user);
    this.tokenService.saveGroupFromUser(login_data?.data?.user);
    await this.router.navigate(['/']);
/*    this.snackbar.open('Lucky you! Looks like you didn\'t need a password or email address! For a real application we provide validators to prevent this. ;)', 'LOL THANKS', {
      duration: 10000
    });*/
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = 'password';
      this.visible = false;
      this.cd.markForCheck();
    } else {
      this.inputType = 'text';
      this.visible = true;
      this.cd.markForCheck();
    }
  }
}
