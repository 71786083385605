import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartModule } from 'src/@vex/components/chart/chart.module';
import { MatIconModule } from '@angular/material/icon';
import { WidgetQuickLineChartModule } from 'src/@vex/components/widgets/widget-quick-line-chart/widget-quick-line-chart.module';
import { WidgetQuickValueCenterModule } from 'src/@vex/components/widgets/widget-quick-value-center/widget-quick-value-center.module';
import { WidgetQuickValueStartModule } from 'src/@vex/components/widgets/widget-quick-value-start/widget-quick-value-start.module';
import { WidgetLargeGoalChartModule } from 'src/@vex/components/widgets/widget-large-goal-chart/widget-large-goal-chart.module';
import { WidgetAssistantModule } from 'src/@vex/components/widgets/widget-assistant/widget-assistant.module';
import {WidgetLargeChartModule} from '../../@vex/components/widgets/widget-large-chart/widget-large-chart.module';
import {WidgetTableModule} from '../../@vex/components/widgets/widget-table/widget-table.module';
import {SecondaryToolbarModule} from '../../@vex/components/secondary-toolbar/secondary-toolbar.module';
import {BreadcrumbsModule} from '../../@vex/components/breadcrumbs/breadcrumbs.module';
import {PageLayoutModule} from '../../@vex/components/page-layout/page-layout.module';
import {MatButtonModule} from '@angular/material/button';
import {DashboardComponent} from './dashboard.component';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {WidgetHealthComponent} from './widgets/widget-health/widget-health.component';
import {MatMenuModule} from '@angular/material/menu';
import {DashboardV2Component} from './dashboard-v2.component';
import {WidgetGroupChartComponent} from './widgets/group-chart/widget-group-chart.component';


@NgModule({
  declarations: [DashboardComponent, DashboardV2Component, WidgetHealthComponent, WidgetGroupChartComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ChartModule,
    MatIconModule,
    MatMenuModule,
    WidgetQuickLineChartModule,
    WidgetQuickValueCenterModule,
    WidgetQuickValueStartModule,
    WidgetLargeGoalChartModule,
    WidgetQuickValueCenterModule,
    WidgetAssistantModule,
    WidgetLargeChartModule,
    WidgetTableModule,
    SecondaryToolbarModule,
    BreadcrumbsModule,
    MatButtonModule,
    PageLayoutModule
  ]
})
export class DashboardModule {
}
