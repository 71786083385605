<table @stagger [dataSource]="dataSource" class="w-full" mat-table>

  <!-- Text Columns -->
  <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
    <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
      <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label }}</th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
    </ng-container>

    <ng-container *ngIf="column.type === 'badge'" [matColumnDef]="column.property">
      <th *matHeaderCellDef class="uppercase" mat-header-cell> {{ column.label }}</th>
      <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property]['name'] }}</td>
    </ng-container>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="actions">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
      <button (click)="$event.stopPropagation()"
              [matMenuTriggerData]="{ group: row }"
              [matMenuTriggerFor]="actionsMenu"
              mat-icon-button
              type="button">
        <mat-icon svgIcon="mat:more_horiz"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
  <tr (click)="select(row)"
      *matRowDef="let row; columns: visibleColumns;"
      @fadeInUp
      class="hover:bg-hover trans-ease-out cursor-pointer"
      mat-row></tr>
</table>

<mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" (page)="pageChanged($event)"
               class="sticky left-0"></mat-paginator>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-group="group" matMenuContent>
    <button mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>Modify</span>
    </button>
  </ng-template>
</mat-menu>
