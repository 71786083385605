import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {ITagCreate, ITagRead, ITagReadWithAsset, ITagUpdate} from '../models/tag';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';
import {IZoneWithCurrentTagsRead} from '../models/realtime-event';

@Injectable({
  providedIn: 'root',
})
export class EventService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetTagsByZonePath = '/v1/events/realtime/tags_by_zone';

  getTagsByZone(context?: HttpContext): Observable<IGetResponse<IZoneWithCurrentTagsRead[]>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.GetTagsByZonePath, 'get');
    rb.query('group_id', this.groupId, {});

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IZoneWithCurrentTagsRead[]>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IZoneWithCurrentTagsRead[]>>) => r.body as IGetResponse<IZoneWithCurrentTagsRead[]>)
    );
  }
}
