import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'luc-widget-health',
  templateUrl: './widget-health.component.html'
})
export class WidgetHealthComponent implements OnInit {

  @Input() title: string;
  @Input() goodValue: string;
  @Input() goodText: string;
  @Input() badValue: string;
  @Input() badText: string;

  constructor() { }

  ngOnInit() {
  }

}
