<vex-secondary-toolbar current="Dashboard">
  <vex-breadcrumbs [crumbs]="['Dashboards', 'Health']" class="flex-auto"></vex-breadcrumbs>

  <button class="ml-1" color="primary" mat-icon-button type="button" [mat-menu-trigger-for]="menu">
    Last Seen 24hs
  </button>

  <mat-menu #menu>
    <button mat-menu-item>
      <span> In the last day </span>
    </button>
    <button mat-menu-item>
      <span> In the last week </span>
    </button>
    <button mat-menu-item>
      <span> Mora than a week ago </span>
    </button>
  </mat-menu>

  <button class="ml-20" color="primary" mat-icon-button type="button">
    <mat-icon svgIcon="mat:more_vert"></mat-icon>
  </button>
</vex-secondary-toolbar>
<div class="card bg-primary flex sm:col-span-2">
  <div class="p-6 text-primary-contrast flex-auto">
    <h2 class="headline m-0 content-center">Total Assets: <span class="display-2 font-bold mt-4 mb-2">10,000</span></h2>
  </div>
</div>
<div class=" p-gutter grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
  <vex-widget-quick-value-center [change]="12.4"
                                 icon="mat:call_received"
                                 iconClass="text-teal bg-teal-light"
                                 label="Total Assets Onsite"
                                 value="8,498"></vex-widget-quick-value-center>
  <vex-widget-quick-value-center [change]="0"
                                 icon="mat:toys"
                                 iconClass="text-teal bg-teal-light"
                                 label="Racks in transit"
                                 value="1,789"></vex-widget-quick-value-center>
  <vex-widget-quick-value-center [change]="0"
                                 icon="mat:info"
                                 iconClass="text-orange bg-orange-light"
                                 label="Total Assets Lost"
                                 value="32"></vex-widget-quick-value-center>
  <vex-widget-quick-value-center [change]="0"
                                 icon="mat:not_interested"
                                 iconClass="text-red bg-red-light"
                                 label="Total Assets Very Lost"
                                 value="5"></vex-widget-quick-value-center>
  <widget-group-chart [series]="userSessionsSeries" class="sm:col-span-2"></widget-group-chart>

  <vex-widget-quick-value-center [change]="12.4"
                                 icon="mat:timer"
                                 iconClass="text-teal bg-teal-light"
                                 label="Avg. time in transit"
                                 value="6h 31m"></vex-widget-quick-value-center>
  <vex-widget-quick-value-center [change]="-26.3"
                                 icon="mat:timer"
                                 iconClass="text-teal bg-teal-light"
                                 label="Avg. time in site"
                                 value="2d 4h 31m"></vex-widget-quick-value-center>
</div>
