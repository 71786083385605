<form (ngSubmit)="save()" [formGroup]="form">
  <div class="flex items-center" mat-dialog-title>

    <h2 *ngIf="form.get('name').value"
        class="headline m-0 flex-auto">{{ form.get('name').value }}</h2>
    <h2 *ngIf="!form.get('name').value"
        class="headline m-0 flex-auto">New Data Stream</h2>

    <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">
      <mat-icon svgIcon="mat:more_vert"></mat-icon>
    </button>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Name</mat-label>
        <input cdkFocusInitial formControlName="name" matInput>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Protocol</mat-label>
        <input formControlName="protocol" matInput>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-12 flex-auto">
        <mat-label>Host</mat-label>
        <input formControlName="host" matInput>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Port</mat-label>
        <input formControlName="port" matInput>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Path</mat-label>
        <input formControlName="path" matInput>
      </mat-form-field>
    </div>
    <div class="flex flex-col sm:flex-row">
      <mat-form-field class="mt-6 flex-auto">
        <mat-label>Username</mat-label>
        <input formControlName="username" matInput>
      </mat-form-field>

      <mat-form-field class="sm:mt-6 sm:ml-6 flex-auto">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Cancel</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button type="submit">Create Data Stream</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button type="submit">Update Data Stream</button>
  </mat-dialog-actions>
</form>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item>
    <mat-icon svgIcon="mat:print"></mat-icon>
    <span>Print</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:download"></mat-icon>
    <span>Export</span>
  </button>

  <button mat-menu-item>
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>
