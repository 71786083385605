import { Component, OnInit } from '@angular/core';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {defaultChartOptions} from '../../@vex/utils/default-chart-options';
import {UserService} from "../common/services/user.service";

@Component({
  selector: 'vex-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.scss']
})
export class DashboardV2Component implements OnInit {

  constructor(private userService: UserService) {

  }

  async ngOnInit() {
    const user = await this.userService.getMyData().toPromise();
  }

  tableColumns: TableColumn<any>[] = [
    {
      label: '',
      property: 'status',
      type: 'badge'
    },
    {
      label: 'PRODUCT',
      property: 'name',
      type: 'text'
    },
    {
      label: '$ PRICE',
      property: 'price',
      type: 'text',
      cssClasses: ['font-medium']
    },
    {
      label: 'DATE',
      property: 'timestamp',
      type: 'text',
      cssClasses: ['text-secondary']
    }
  ];

  series: ApexAxisChartSeries = [{
    name: 'Subscribers',
    data: [28, 40, 36, 0, 52, 38, 60, 55, 67, 33, 89, 44]
  }];

  userSessionsSeries: ApexAxisChartSeries = [
    {
      name: 'Onsite',
      data: [{x: 'Site 1', y: 10}, {x: 'Site 2', y: 50}, {x: 'Site 3', y: 26}, {x: 'Site 4', y: 50}, {x: 'Lab', y: 38}]
    },
    {
      name: 'In transit',
      data: [{x: 'Site 1', y: 5}, {x: 'Site 2', y: 21}, {x: 'Site 3', y: 42}, {x: 'Site 4', y: 70}, {x: 'Lab', y: 41}]
    },
    {
      name: 'Lost',
      data: [{x: 'Site 1', y: 3}, {x: 'Site 2', y: 7}, {x: 'Site 3', y: 8}, {x: 'Site 4', y: 9}, {x: 'Lab', y: 1}]
    },
    {
      name: 'Very Lost',
      data: [{x: 'Site 1', y: 1}, {x: 'Site 2', y: 5}, {x: 'Site 3', y: 4}, {x: 'Site 4', y: 3}, {x: 'Lab', y: 0}]
    },
  ];

  salesSeries: ApexAxisChartSeries = [{
    name: 'Sales',
    data: [28, 40, 36, 0, 52, 38, 60, 55, 99, 54, 38, 87]
  }];

  pageViewsSeries: ApexAxisChartSeries = [{
    name: 'Page Views',
    data: [405, 800, 200, 600, 105, 788, 600, 204]
  }];

  uniqueUsersSeries: ApexAxisChartSeries = [{
    name: 'Unique Users',
    data: [356, 806, 600, 754, 432, 854, 555, 1004]
  }];

  uniqueUsersOptions = defaultChartOptions({
    chart: {
      type: 'area',
      height: 100
    },
    colors: ['#ff9800']
  });

}
