import { Component, Input, OnInit } from '@angular/core';
import {ApexOptions} from '../../../../@vex/components/chart/chart.component';
import {defaultChartOptions} from '../../../../@vex/utils/default-chart-options';
import {createDateArray} from '../../../../@vex/utils/create-date-array';
@Component({
  selector: 'widget-group-chart',
  templateUrl: './widget-group-chart.component.html',
  styleUrls: ['./widget-group-chart.component.scss']
})
export class WidgetGroupChartComponent implements OnInit {

  @Input() series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  @Input() options: ApexOptions = defaultChartOptions({
    grid: {
      show: true,
      strokeDashArray: 3,
      padding: {
        left: 16
      }
    },
    chart: {
      type: 'bar',
      height: 384,
      sparkline: {
        enabled: false
      },
      zoom: {
        enabled: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 90, 100]
      }
    },
    colors: ['#008ffb', '#1ab01c', '#ff9800', '#c92121'],
    dataLabels: {enabled: false},
    xaxis: { labels: {show: true}},
    yaxis: {
      labels: {
        show: true
      },
      title: {
        text: "Num. of Assets"
      }
    },
    legend: {
      show: true,
      itemMargin: {
        horizontal: 4,
        vertical: 4
      }
    }
  });

/*{
  series: [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    },
    {
      name: "Revenue",
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    },
    {
      name: "Free Cash Flow",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    }
  ],
  chart: {
    type: "bar",
    height: 350
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%",
      endingShape: "rounded"
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"]
  },
  xaxis: {
    categories: [
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct"
    ]
  },
  yaxis: {
    title: {
      text: "$ (thousands)"
    }
  },
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function(val) {
  return "$ " + val + " thousands";
}
}
}
};*/

  constructor() { }

  ngOnInit() {
  }

}
