import { Injectable } from '@angular/core';
import {IUserGroupRoleRead, IUserRead} from '../models/user';
import {IGroupRead} from '../models/group';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';
const GROUP_KEY = 'current-group';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public clearAll() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.removeItem(USER_KEY);
  }

  public getGroup(): IGroupRead {
    const groupStr = window.sessionStorage.getItem(GROUP_KEY);
    if (groupStr) {
      return JSON.parse(groupStr);
    }

    return null;
  }

  saveGroupFromUser(user: IUserRead) {
    const current_group = this.getGroup();
    if (current_group) {
      const hasGroupRole = user.group_roles.find((group_role) =>
        group_role.group.id === current_group.id);
      if (hasGroupRole) {
        this.saveGroup(hasGroupRole.group)
        return;
      }
    }
    const first_group_role = user.group_roles.find((gr) => !!gr)
    this.saveGroup(first_group_role.group)
  }

  saveGroup(group: IGroupRead) {
    window.sessionStorage.removeItem(GROUP_KEY);
    window.sessionStorage.setItem(GROUP_KEY, JSON.stringify(group));
  }
}
