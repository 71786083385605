import {UntypedFormControl} from '@angular/forms';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {TableColumn} from '../../../@vex/interfaces/table-column.interface';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ContextService} from '../services/context.service';

@UntilDestroy()
@Component({selector: 'base-abstract', template: ''})
export abstract class BaseListComponent<T> implements OnInit{
  layoutCtrl = new UntypedFormControl('fullwidth');
  @Input()
  abstract columns: TableColumn<T>[];
  pageSize = 10;
  page = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<T> | null;
  selection = new SelectionModel<T>(true, []);
  searchCtrl = new UntypedFormControl();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public $loading = false;

  protected constructor(protected contextService: ContextService) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  abstract load();

  async ngOnInit() {
    this.contextService.selectedGroup$.subscribe(group => {
      if (!group) {
        return;
      }
      this.load();
    })
    this.dataSource = new MatTableDataSource();
    await this.load();

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  async pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    await this.load();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
}
