import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {sensorsLink} from '../../../static-data/sensors-link';
import {summary} from '../../../static-data/summary';

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient) { }

  getSensors() {
    if (environment.offline) {
      return of(sensorsLink);
    }
    return this.httpClient.get<any[]>(environment.api + '/sensors');
  }

  getEdges() {
    return this.httpClient.get<any[]>(environment.api + '/edges');
  }

  getAssets() {
    return this.httpClient.get<any[]>(environment.api + '/assets');
  }

  getSummary() {
    if (environment.offline) {
      return of(summary);
    }
    return this.httpClient.get<any[]>(environment.api + '/summary');
  }
}
