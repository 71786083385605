import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {fadeInUp400ms} from '../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../@vex/animations/stagger.animation';
import {IGetResponsePaginated} from '../common/models/get-response';
import {BaseListComponent} from '../common/base/base-list.component';
import {ContextService} from '../common/services/context.service';
import {DataStreamsCreateUpdateComponent} from './data-streams-create-update/data-streams-create-update.component';
import {DataStreamService} from '../common/services/data-stream.service';
import {IDataStreamCreate, IDataStreamRead, IDataStreamUpdate} from '../common/models/data-stream';


@UntilDestroy()
@Component({
  selector: 'vex-aio-table',
  templateUrl: './data-streams-list.component.html',
  styleUrls: ['./data-streams-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class DataStreamsListComponent extends BaseListComponent<IDataStreamRead> implements OnInit {
  @Input()
  columns: TableColumn<IDataStreamRead>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Protocol', property: 'protocol', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Host', property: 'host', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Port', property: 'port', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Path', property: 'path', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  private dataStreamsResponse: IGetResponsePaginated<IDataStreamRead>;

  constructor(private dialog: MatDialog, private dataStreamService: DataStreamService,
              protected contextService: ContextService) {
    super(contextService);
  }

  async load() {
    this.$loading = true;
    this.dataStreamsResponse = await this.dataStreamService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.dataSource.data = this.dataStreamsResponse.data.items;
    this.paginator.pageIndex = (this.dataStreamsResponse.data.page - 1);
    this.paginator.length = this.dataStreamsResponse.data.total;
    this.$loading = false;
  }

  async create() {
    const reader: IDataStreamCreate = await this.dialog.open(DataStreamsCreateUpdateComponent).afterClosed().toPromise();
    if (reader) {
      const createdAsset = await this.dataStreamService.create({body: reader}).toPromise();
      this.page = 0;
      await this.load();
    }
  }

  async update(data_stream: IDataStreamUpdate) {
    const updatedAsset = await this.dialog.open(DataStreamsCreateUpdateComponent, {
      data: data_stream
    }).afterClosed().toPromise();
    if (updatedAsset) {
      const res = await this.dataStreamService.update(
        {data_stream_id: data_stream.id, body: updatedAsset}).toPromise()
      this.page = 0;
      await this.load();
    }
  }

  async delete(data_stream: IDataStreamRead) {
    await this.dataStreamService.remove({data_stream_id: data_stream.id}).toPromise();
    this.page = 0;
    await this.load();
  }
}
