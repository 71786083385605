import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';
import {IDataStreamCreate, IDataStreamRead, IDataStreamUpdate} from '../models/data-stream';


@Injectable({
  providedIn: 'root',
})
export class DataStreamService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetDataStreamsListPath = '/v1/data_stream';
  static readonly CreateDataStreamPath = '/v1/data_stream';
  static readonly GetDataStreamByIdPath = '/v1/data_stream/{data_stream_id}';
  static readonly UpdateDataStreamPath = '/v1/data_stream/{data_stream_id}';
  static readonly RemoveDataStreamPath = '/v1/data_stream/{data_stream_id}';


  getList(params: {
    page?: number;
    size?: number;
  },
  context?: HttpContext

): Observable<IGetResponsePaginated<IDataStreamRead>> {

    const rb = new RequestBuilder(this.rootUrl, DataStreamService.GetDataStreamsListPath, 'get');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IDataStreamRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IDataStreamRead>>) => r.body as IGetResponsePaginated<IDataStreamRead>)
    );
  }

  create(params: {
    body: IDataStreamCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IDataStreamRead>> {

    const rb = new RequestBuilder(this.rootUrl, DataStreamService.CreateDataStreamPath, 'post');
    if (params) {
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IDataStreamRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IDataStreamRead>>) => r.body as IPostResponse<IDataStreamRead>)
    );
  }

  getById(params: {
    data_stream_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IDataStreamRead>> {

    const rb = new RequestBuilder(this.rootUrl, DataStreamService.GetDataStreamByIdPath, 'get');
    if (params) {
      rb.path('data_stream_id', params.data_stream_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IDataStreamRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IDataStreamRead>>) => r.body as IGetResponse<IDataStreamRead>)
    );
  }


  update(params: {
           data_stream_id: string;
    body: IDataStreamUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<IDataStreamRead>> {

    const rb = new RequestBuilder(this.rootUrl, DataStreamService.UpdateDataStreamPath, 'put');
    if (params) {
      rb.path('data_stream_id', params.data_stream_id, {});
      rb.query('group_id', this.groupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IDataStreamRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IDataStreamRead>>) => r.body as IPostResponse<IDataStreamRead>)
    );
  }

  remove(params: {
           data_stream_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IDataStreamRead>> {

    const rb = new RequestBuilder(this.rootUrl, DataStreamService.RemoveDataStreamPath, 'delete');
    if (params) {
      rb.path('data_stream_id', params.data_stream_id, {});
      rb.query('group_id', this.groupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IDataStreamRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IDataStreamRead>>) => r.body as IDeleteResponse<IDataStreamRead>)
    );
  }
}
