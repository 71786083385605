import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IGroupCreate, IGroupRead, IGroupUpdate} from '../models/group';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import { IPostResponse } from '../models/post-response';
import {IPutResponse} from '../models/put-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetGroupsPath = '/v1/group';
  static readonly CreatePath = '/v1/group';
  static readonly GetGroupByIdPath = '/v1/group/{group_id}';
  static readonly UpdateGroupPath = '/v1/group/{group_id}';

  getList(params?: {
            page?: number;
            size?: number;
          },
          context?: HttpContext
  ): Observable<IGetResponsePaginated<IGroupRead>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetGroupsPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IGroupRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IGroupRead>>) => r.body as IGetResponsePaginated<IGroupRead>)
    );
  }


  create(params: {
           body: IGroupCreate
         },
         context?: HttpContext
  ): Observable<IPostResponse<IGroupRead>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.CreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IGroupRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IGroupRead>>) => r.body as IPostResponse<IGroupRead>)
    );
  }


  getById(params: {
            group_id: string;
          },
          context?: HttpContext
  ): Observable<IGetResponse<IGroupRead>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.GetGroupByIdPath, 'get');
    if (params) {
      rb.path('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IGroupRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IGroupRead>>) => r.body as IGetResponse<IGroupRead>)
    );
  }

  update(params: {
           group_id: string;
           body: IGroupUpdate
         },
         context?: HttpContext
  ): Observable<IPutResponse<IGroupRead>> {

    const rb = new RequestBuilder(this.rootUrl, GroupService.UpdateGroupPath, 'put');
    if (params) {
      rb.path('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPutResponse<IGroupRead>>;
      }),
      map((r: StrictHttpResponse<IPutResponse<IGroupRead>>) => r.body as IPutResponse<IGroupRead>)
    );
  }
}
