import { NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {MapResolver} from './common/resolvers/map.resolver';
import {placeResolver} from './common/resolvers/place.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboard',
        redirectTo: '/',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'assets',
        loadChildren: () => import('./assets/assets-list.module').then(m => m.AssetsListModule),
      },
      {
        path: 'readers',
        loadChildren: () => import('./readers/readers-list.module').then(m => m.ReadersListModule),
      },
      {
        path: 'tags',
        loadChildren: () => import('./tags/tags-list.module').then(m => m.TagsListModule),
      },
      {
        path: 'places',
        loadChildren: () => import('./places/places-list.module').then(m => m.PlacesListModule),
      },
      {
        path: 'data-streams',
        loadChildren: () => import('./data-streams/data-streams-list.module').then(m => m.DataStreamsListModule),
      },
      {
        path: 'reports',
        children: [
          {
            path: 'movement',
            loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
