import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../services/api.service';

@Injectable()
export class MapResolver implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    const objectId = route.params['id'];
    return of({sensors: [], summary: []});
  }
}
