import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IZoneCreate, IZoneRead, IZoneUpdate} from '../models/zone';
import {IGetResponse} from '../models/get-response';
import {IPostResponse} from '../models/post-response';
import {IDeleteResponse} from '../models/delete-response';
import {TokenService} from './token.service';


@Injectable({
  providedIn: 'root',
})
export class ZoneService extends BaseService {
  constructor( http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetZoneByIdApiV1ZoneZoneIdGetPath = '/api/v1/zone/{zone_id}';
  static readonly UpdateZoneApiV1ZoneZoneIdPutPath = '/api/v1/zone/{zone_id}';
  static readonly RemoveZoneApiV1ZoneZoneIdDeletePath = '/api/v1/zone/{zone_id}';

  getZoneByIdApiV1ZoneZoneIdGet$Response(params: {
    zone_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IGetResponse<IZoneRead>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.GetZoneByIdApiV1ZoneZoneIdGetPath, 'get');
    if (params) {
      rb.path('zone_id', params.zone_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IZoneRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IZoneRead>>) => r.body as IGetResponse<IZoneRead>)
    );
  }


  update(params: {
    zone_id: string;
    group_id: string;
    body: IZoneUpdate
  },
  context?: HttpContext

): Observable<IPostResponse<IZoneRead>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.UpdateZoneApiV1ZoneZoneIdPutPath, 'put');
    if (params) {
      rb.path('zone_id', params.zone_id, {});
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IZoneRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IZoneRead>>) => r.body as IPostResponse<IZoneRead>)
    );
  }

  remove(params: {
    zone_id: string;
    group_id: string;
  },
  context?: HttpContext

): Observable<IDeleteResponse<IZoneRead>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.RemoveZoneApiV1ZoneZoneIdDeletePath, 'delete');
    if (params) {
      rb.path('zone_id', params.zone_id, {});
      rb.query('group_id', params.group_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IDeleteResponse<IZoneRead>>;
      }),
      map((r: StrictHttpResponse<IDeleteResponse<IZoneRead>>) => r.body as IDeleteResponse<IZoneRead>)
    );
  }


  static readonly CreateZoneApiV1ZonePostPath = '/api/v1/zone';

  create(params: {
    group_id: string;
    body: IZoneCreate
  },
  context?: HttpContext

): Observable<IPostResponse<IZoneRead>> {

    const rb = new RequestBuilder(this.rootUrl, ZoneService.CreateZoneApiV1ZonePostPath, 'post');
    if (params) {
      rb.query('group_id', params.group_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IPostResponse<IZoneRead>>;
      }),
      map((r: StrictHttpResponse<IPostResponse<IZoneRead>>) => r.body as IPostResponse<IZoneRead>)
    );
  }
}
