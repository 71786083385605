<vex-secondary-toolbar current="Dashboard">
  <vex-breadcrumbs [crumbs]="['Dashboards', 'Health']" class="flex-auto"></vex-breadcrumbs>

  <button class="ml-1" color="primary" mat-icon-button type="button" [mat-menu-trigger-for]="menu">
    Last Seen 24hs
  </button>

  <mat-menu #menu>
    <button mat-menu-item>
      <span> In the last day </span>
    </button>
    <button mat-menu-item>
      <span> In the last week </span>
    </button>
    <button mat-menu-item>
      <span> Mora than a week ago </span>
    </button>
  </mat-menu>

  <button class="ml-20" color="primary" mat-icon-button type="button">
    <mat-icon svgIcon="mat:more_vert"></mat-icon>
  </button>
</vex-secondary-toolbar>

<div class="container p-gutter grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
  <h1 class="m-0 title mb-6 sm:col-span-full" style="font-size: 2.0em">BT 5100 Tags</h1>
  <luc-widget-health class="sm:col-span-2" title="Battery" goodText="Tags with good battery level" goodValue="99"
                     badText="Tags with bad battery level" badValue="01"></luc-widget-health>
  <luc-widget-health class="sm:col-span-2" title="Last Seen" goodText="Seen tags" goodValue="99"
                     badText="Not seen tags" badValue="01"></luc-widget-health>

  <h1 class="m-0 title mb-6 sm:col-span-full" style="font-size: 2.0em">BT 710 Tags</h1>
  <luc-widget-health class="sm:col-span-2" title="Battery" goodText="Tags with good battery level" goodValue="99"
                     badText="Tags with bad battery level" badValue="01"></luc-widget-health>
  <luc-widget-health class="sm:col-span-2" title="Last Seen" goodText="Seen tags" goodValue="99"
                     badText="Not seen tags" badValue="01"></luc-widget-health>
</div>
