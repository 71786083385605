import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSelectChange } from '@angular/material/select';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {AssetCreateUpdateComponent} from './asset-create-update/asset-create-update.component';
import {fadeInUp400ms} from '../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../@vex/animations/stagger.animation';
import {AssetService} from '../common/services/asset.service';
import {IAssetCreate, IAssetRead, IAssetUpdate} from '../common/models/assets';
import {IGetResponsePaginated} from '../common/models/get-response';
import {BaseListComponent} from '../common/base/base-list.component';
import {IReaderRead} from '../common/models/reader';
import {ContextService} from '../common/services/context.service';


@UntilDestroy()
@Component({
  selector: 'lll-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class AssetsListComponent extends BaseListComponent<IAssetRead> implements OnInit {

  layoutCtrl = new UntypedFormControl('fullwidth');

  @Input()
  columns: TableColumn<IAssetRead>[] = [
    { label: 'Checkbox', property: 'checkbox', type: 'checkbox', visible: true },
    { label: 'Label', property: 'label', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Serial Number', property: 'serial_number', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Last Location', property: 'lastLocation', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Type', property: 'type', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
/*    { label: 'Labels', property: 'labels', type: 'button', visible: true },*/
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];

  private assetsResponse: IGetResponsePaginated<IAssetRead>;

  constructor(private dialog: MatDialog, private assetService: AssetService, protected contextService: ContextService) {
    super(contextService);
  }

  async load() {
    this.$loading = true;
    this.assetsResponse = await this.assetService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.dataSource.data = this.assetsResponse.data.items;
    this.paginator.pageIndex = (this.assetsResponse.data.page - 1);
    this.paginator.length = this.assetsResponse.data.total;
    this.$loading = false;
  }

  async create() {
    const asset: IAssetCreate = await this.dialog.open(AssetCreateUpdateComponent).afterClosed().toPromise();
    if (asset) {
      const createdAsset = await this.assetService.create({body: asset}).toPromise();
      this.page = 0;
      await this.load();
    }
  }

  async update(asset: IAssetUpdate) {
    const updatedAsset = await this.dialog.open(AssetCreateUpdateComponent, {
      data: asset
    }).afterClosed().toPromise();
    if (updatedAsset) {
      const res = await this.assetService.update(
        {asset_id: asset.id, body: updatedAsset}).toPromise()
      this.page = 0;
      await this.load();
    }
  }

  async delete(asset: IAssetRead) {
    await this.assetService.remove({asset_id: asset.id}).toPromise();
    this.page = 0;
    await this.load();
  }
}
