import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IGroupRead} from '../common/models/group';
import {MatTableDataSource} from '@angular/material/table';
import {TableColumn} from '../../@vex/interfaces/table-column.interface';
import {GroupService} from '../common/services/group.service';
import {IGetResponsePaginated} from '../common/models/get-response';
import {IReaderRead} from '../common/models/reader';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {fadeInUp400ms} from '../../@vex/animations/fade-in-up.animation';
import {stagger40ms} from '../../@vex/animations/stagger.animation';

@Component({
  selector: 'vex-customer-create-update',
  templateUrl: './groups-pick.component.html',
  styleUrls: ['./groups-pick.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
})
export class GroupsPickComponent implements OnInit {
  pageSize = 10;
  page = 0;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  dataSource: MatTableDataSource<IGroupRead> | null;

  columns: TableColumn<IGroupRead>[] = [
    { label: 'Name', property: 'name', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'Description', property: 'description', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Type', property: 'group_type', type: 'badge', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: true }
  ];
  private groupsResponse: IGetResponsePaginated<IGroupRead>;
  public $loading = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
              private dialogRef: MatDialogRef<GroupsPickComponent>,
              private groupService: GroupService,) {
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  async ngOnInit() {
    this.dataSource = new MatTableDataSource();
    await this.load();
  }

  async getData() {
    this.$loading = true;
    this.groupsResponse = await this.groupService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.$loading = false;
  }

  async load() {
    await this.getData();
    this.dataSource.data = this.groupsResponse?.data?.items;
    this.paginator.pageIndex = (this.groupsResponse.data.page - 1);
    this.paginator.length = this.groupsResponse.data.total;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  async select(row) {
    if(!row) return;
    this.dialogRef.close(row);
  }

  async pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex;
    await this.load();
  }
}
