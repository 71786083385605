import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from './base-service';
import { StrictHttpResponse } from '../utils/strict-http-response';
import { RequestBuilder } from '../utils/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {IRoleRead} from '../models/group';
import {IGetResponse, IGetResponsePaginated} from '../models/get-response';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService {
  constructor(http: HttpClient, tokenService: TokenService) {
    super(http, tokenService);
  }

  static readonly GetRolesPath = '/api/v1/role';
  static readonly GetRoleByIdPath = '/api/v1/role/{role_id}';


  getList(params?: {
            page?: number;
            size?: number;
          },
          context?: HttpContext
  ): Observable<IGetResponsePaginated<IRoleRead>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetRolesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponsePaginated<IRoleRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponsePaginated<IRoleRead>>) => r.body as IGetResponsePaginated<IRoleRead>)
    );
  }

  getById(params: {
            role_id: string;
          },
          context?: HttpContext
  ): Observable<IGetResponse<IRoleRead>> {

    const rb = new RequestBuilder(this.rootUrl, RoleService.GetRoleByIdPath, 'get');
    if (params) {
      rb.path('role_id', params.role_id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IGetResponse<IRoleRead>>;
      }),
      map((r: StrictHttpResponse<IGetResponse<IRoleRead>>) => r.body as IGetResponse<IRoleRead>)
    );
  }
}
