import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {MovementReport} from './interfaces/movement-report.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {stagger40ms} from '../../../@vex/animations/stagger.animation';
import {fadeInUp400ms} from '../../../@vex/animations/fade-in-up.animation';
import {TableColumn} from '../../../@vex/interfaces/table-column.interface';
import {movementReportTableData} from '../../../static-data/movement-report-data';
import {AggregationService} from '../../common/services/aggregation.service';
import {BaseListComponent} from '../../common/base/base-list.component';
import {IPlaceRead} from '../../common/models/place';
import {AggregationZoneChangeEvent} from '../../common/models/aggregation';
import {ContextService} from '../../common/services/context.service';
import {IGetResponsePaginated} from '../../common/models/get-response';



@UntilDestroy()
@Component({
  selector: 'vex-aio-table',
  templateUrl: './movement-report.component.html',
  styleUrls: ['./movement-report.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class MovementReportComponent extends BaseListComponent<AggregationZoneChangeEvent> implements OnInit {

  layoutCtrl = new UntypedFormControl('boxed');

  @Input()
  columns: TableColumn<MovementReport>[] = [
    { label: 'Asset', property: 'tag_mac_address', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'From Location', property: 'old_zone_id', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'To Location', property: 'new_zone_id', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Time of entry', property: 'new_zone_enter_time', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Time of exit', property: 'new_zone_exit_time', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Duration', property: 'total_time_in_new_zone', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] }
  ];
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 20, 50];
  searchCtrl = new UntypedFormControl();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private aggregationsResponse: IGetResponsePaginated<AggregationZoneChangeEvent>;

  constructor(private aggregationService: AggregationService,
              private dialog: MatDialog,
              protected contextService: ContextService) {
    super(contextService);
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  async load() {
    this.$loading = true;
    this.aggregationsResponse = await this.aggregationService.getList(
      {page: this.page + 1, size: this.pageSize}).toPromise();
    this.dataSource.data = this.aggregationsResponse.data.items;
    this.paginator.pageIndex = (this.aggregationsResponse.data.page - 1);
    this.paginator.length = this.aggregationsResponse.data.total;
    this.$loading = false;
  }


  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
}
